import React from 'react'
import { css } from '@emotion/react'
import { Divider, colors, font } from 'plume-ui'
import { Menu } from './Menu'
import { OrganizationLogo } from '../common/OrganizationLogo'
import { NotificationItem } from '../../types'
import {
  logoutUrl,
  downloadHistoryUrl,
  followListUrl,
  myPageUrl,
  notificationUrl,
  statusSettingUrl,
} from '@/lib/url'
import { formatDate } from '@/lib/date'
import { isLatestEnterpriseOrTrial } from '@/lib/user'
import { useUser } from '../../contexts/AuthContext'
import { npidLogout } from '@/lib/api/auth'
import { ssoLogout } from '@/lib/api/auth'

export type Feature = 'notification' | 'history' | 'moderator' | 'profile'

type Props = {
  opened?: Feature | undefined
  notifications: NotificationItem[]
}

export const ToolbarMenu: React.FC<Props> = (props) => {
  return (
    <>
      <NotificationMenu
        data={props.notifications}
        isOpen={props.opened === 'notification'}
      />
      <ProfileMenu isOpen={props.opened === 'profile'} />
    </>
  )
}

type MenuProps = {
  isOpen: boolean
}

type DataMenuProps<T> = MenuProps & {
  data: T[]
}

const ProfileMenu: React.FC<MenuProps> = (props) => {
  const handleLogout = () => {
    npidLogout()
    ssoLogout()
    location.href = logoutUrl
  }
  const user = useUser()
  return (
    <Menu show={props.isOpen} id="account-menu">
      <a href={myPageUrl}>アカウント設定</a>
      <a href={followListUrl}>フォローリスト</a>
      <a href={downloadHistoryUrl}>ダウンロード</a>
      {isLatestEnterpriseOrTrial(user) && (
        <a href={statusSettingUrl}>ステータス設定</a>
      )}
      <Divider />
      <a
        href="https://help.initial.inc/"
        target="_blank"
        rel="noopener noreferrer"
      >
        ヘルプ
      </a>
      <Divider />
      <button
        css={css`
          width: 100%;
          text-align: left;
          background-color: transparent;
          border: none;
          cursor: pointer;
          outline: none;
          padding: 0;
          appearance: none;
          font-size: 14px;
          letter-spacing: 0.02em;
          padding: 12px 16px;
          height: 38px;
          color: ${colors.text};
          &:hover {
            background-color: ${colors.backgroundGray};
          }
        `}
        onClick={handleLogout}
      >
        ログアウト
      </button>
    </Menu>
  )
}

const NotificationMenu: React.FC<DataMenuProps<NotificationItem>> = (props) => {
  const limit = 7
  return (
    <Menu show={props.isOpen}>
      {props.data.slice(0, limit).map((item) => (
        <NotificationMenuItem item={item} key={item.id} />
      ))}
      <Divider />
      <a href={notificationUrl}>すべて見る</a>
    </Menu>
  )
}

type NotificationMenuItemProps = {
  item: NotificationItem
}

const NotificationMenuItem: React.FC<NotificationMenuItemProps> = (props) => {
  return (
    <a href={props.item.path}>
      <div
        css={css`
          display: flex;
          flex-direction: row;
        `}
      >
        <div
          css={css`
            width: 60px;
          `}
        >
          <OrganizationLogo item={props.item} />
        </div>
        <div
          css={css`
            padding-left: 10px;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: row;
              padding-bottom: 5px;
            `}
          >
            <div
              css={css`
                flex-grow: 1;
                font-weight: ${font.weight.bold};
              `}
            >
              {props.item.title}
            </div>
            <div
              css={css`
                color: ${colors.textLight};
                font-size: 14px;
              `}
            >
              {formatDate(props.item.createdAt, 'yyyy/MM/dd')}
            </div>
          </div>
          <div
            css={css`
              font-size: 14px;
            `}
          >
            {props.item.body}
          </div>
        </div>
      </div>
    </a>
  )
}
